import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['autoSendFrame']
  static values = {
    groupIds: Array,
    autoSendGroupIds: Array,
    url: String,
  }

  connect() {
    console.log('groups checklists connect', this.element)
  }

  checkboxListChangedHandler(event) {
    switch (event.detail.name) {
      case 'checklist-ids':
        this.groupIdsValue = event.detail.selectedValues
        break
      case 'checklist-ids-auto-send':
        this.autoSendGroupIdsValue = event.detail.selectedValues
        break
    }
  }

  groupIdsValueChanged(value, previousValue) {
    // Initial load has a value of undefined
    if (previousValue && previousValue.length !== value.length) {
      // The first selection will often have a list, but we need to support subsequent empty lists
      const separator = this.urlValue.includes('?') ? '&' : '?'
      this.autoSendFrameTarget.src = `${
        this.urlValue
      }${separator}${new URLSearchParams({
        'checklist-ids': value,
        'checklist-ids-auto-send': this.autoSendGroupIdsValue,
      })}`
    }
  }
}
