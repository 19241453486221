import * as Turbo from '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import 'banzai/pendant/front/shared/global.js'
import Alpine from 'alpinejs'
import anchor from '@alpinejs/anchor'

import ResourceController from 'banzai/pendant/front/resource/controller.js'
import BenefitController from 'banzai/pendant/front/benefit/controller.js'
import NavController from 'banzai/pendant/front/shared/nav.js'
import FlyoutController from 'banzai/pendant/front/shared/flyout.js'
import FormsController from 'banzai/pendant/front/shared/components/forms.js'
import AutocompleteController from 'banzai/pendant/front/shared/components/autocomplete.js'
import SendController from 'banzai/pendant/front/send/controller.js'
import CheckboxController from 'banzai/pendant/front/shared/components/checkbox.js'
import ButtonController from 'banzai/pendant/front/shared/components/button.js'
import ModalController from 'banzai/pendant/front/shared/modal.js'
import DiscussController from 'banzai/pendant/front/discuss/controller.js'
import NotificationsController from 'banzai/pendant/front/notifications/controller.js'
import PopupController from 'banzai/pendant/front/shared/popup.js'
import RearrangeableListController from 'banzai/pendant/front/shared/components/rearrangeable_list/controller.js'
import ShortcutsController from 'banzai/pendant/front/shared/shortcuts.js'
import SearchController from 'banzai/pendant/front/search/controller.js'
import KeyboardListController from 'banzai/pendant/front/shared/keyboard-list.js'
import GroupsCounterController from 'banzai/pendant/front/groups/counter.js'
import GroupsRecruitingController from 'banzai/pendant/front/groups/recruiting.js'
import GroupsChecklistsController from 'banzai/pendant/routes/groups/controller.js'
import InputController from 'banzai/pendant/front/shared/components/input.js'
import AnnounceController from 'banzai/pendant/front/announce/view/controller.js'
import VocabController from 'banzai/pendant/front/vocab/controller.js'
import DateController from 'banzai/pendant/front/shared/date-controller.js'
import TabEditorController from 'banzai/pendant/front/checklist/tab-editor.js'
import ServicesController from 'banzai/pendant/front/services/view/controller.js'
import BenefitsPageController from 'banzai/pendant/front/benefits/controller.js'
import ChecklistController from 'banzai/pendant/front/checklist/controller.js'
import StickyHeaderController from 'banzai/pendant/front/shared/sticky-header.js'
import CopyController from 'banzai/pendant/front/shared/components/copy.js'
import OrgController from 'banzai/pendant/front/orgs/org.js'
import AssistantController from 'banzai/pendant/front/assistant/controller.js'
import UpvoteController from 'banzai/pendant/front/benefit/pages/upvote.js'
import VisitorEventsController from 'banzai/pendant/front/visitor_events/controller.js'
import AnalyticsFiltersController from 'banzai/pendant/front/analytics/controllers/analytics_filters_controller.js'
import AnalyticsChartController from 'banzai/pendant/front/analytics/controllers/chart_controller.js'
import QuestionTypeController from 'banzai/pendant/front/survey/view/question_type_controller.js'
import MultipleChoiceController from 'banzai/pendant/front/survey/view/multiple_choice_controller.js'
import AudienceController from 'banzai/pendant/front/survey/view/audience_controller.js'
import BookmarkController from 'banzai/pendant/routes/bookmarks/controller.js'
import FreeController from 'banzai/pendant/routes/free/controller.js'
import PageVersionController from 'banzai/pendant/routes/benefits/pages/versions/controller.js'

// Stimulus setup
const app = Application.start()

app.register('nav', NavController)
app.register('flyout', FlyoutController)
app.register('modal', ModalController)
app.register('resource', ResourceController)
app.register('benefit', BenefitController)
app.register('forms', FormsController)
app.register('autocomplete', AutocompleteController)
app.register('send', SendController)
app.register('checkbox', CheckboxController)
app.register('button', ButtonController)
app.register('discuss', DiscussController)
app.register('notifications', NotificationsController)
app.register('rearrangeable_list', RearrangeableListController)
app.register('shortcuts', ShortcutsController)
app.register('search', SearchController)
app.register('keyboard-list', KeyboardListController)
app.register('groups-counter', GroupsCounterController)
app.register('groups-recruiting', GroupsRecruitingController)
app.register('groups-checklists', GroupsChecklistsController)
app.register('announce', AnnounceController)
app.register('vocab', VocabController)
app.register('date', DateController)
app.register('tab-editor', TabEditorController)
app.register('services', ServicesController)
app.register('benefits-page', BenefitsPageController)
app.register('checklist', ChecklistController)
app.register('sticky-header', StickyHeaderController)
app.register('popup', PopupController)
app.register('input', InputController)
app.register('copy', CopyController)
app.register('org', OrgController)
app.register('assistant', AssistantController)
app.register('upvote', UpvoteController)
app.register('visitor-events', VisitorEventsController)
app.register('analytics-filters', AnalyticsFiltersController)
app.register('analytics-chart', AnalyticsChartController)
app.register('question-type', QuestionTypeController)
app.register('multiple-choice', MultipleChoiceController)
app.register('audience', AudienceController)
app.register('bookmark', BookmarkController)
app.register('free', FreeController)
app.register('page-version', PageVersionController)

Alpine.plugin(anchor)
Alpine.start()
